<template>
    <div id="imageSchowCaseWrapper">
        <section id="showcase" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'imageSchowCaseWrapper')" class="am2-section gallery">
            <div class="gallery__row gallery__row--top" v-bind:style="{transform: getTransformUppderRow.targetHorizontalPosition_CSS}">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider1.png">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider2.png">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider3.png">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider4.png">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider5.png">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider6.png">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider7.png">
            </div>
            <div class="gallery__row gallery__row--bottom" v-bind:style="{transform: getTransformBottomRow.targetHorizontalPosition_CSS}">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider1.png">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider2.png">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider3.png">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider4.png">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider5.png">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider6.png">
                <img loading="lazy" alt="White rabbit" class="gallery__row-item" src="../assets/NFTs/slider7.png">
            </div>
        </section>
    </div>
</template>

<script>


export default {
  name: 'ImageGallery',
  props: [],
  components: {
  },
  data() {
        return {
            viewportHeight : 0,
            eventListener : null,
            scrollStartPosition : 0,
            showcaseInnerHeight : 0,
            maxScrollWidth: 400,
            scrollPercentage : 0,
            startHorizontalPositionUpperRow : -200,
            startHorizontalPositionBottomRow : 200
        }
  },
  methods: {
    visibilityChanged (isVisible, entry, element) {
      if (element == 'imageSchowCaseWrapper') {
        if (isVisible){
            this.viewportHeight = window.outerHeight;
            this.showcaseInnerHeight = document.getElementById('showcase').offsetHeight;
            this.scrollStartPosition = window.scrollY
            this.eventListener = window.addEventListener("scroll", this.scrollGalleryRow);
            if (window.innerWidth <= 1450) {
                this.startHorizontalPositionUpperRow = -1000
                this.startHorizontalPositionBottomRow = 1000
                this.maxScrollWidth = 1600
            } else {
                this.startHorizontalPositionUpperRow = -200
                this.startHorizontalPositionBottomRow = +200
                this.maxScrollWidth = 400
            }
        }else{
            window.removeEventListener("scroll", this.scrollGalleryRow);
        }
      }
    },
    scrollGalleryRow(){
        let scrollAmount_Total = window.scrollY
        let scrollAmount_Relative = Math.abs(scrollAmount_Total - this.scrollStartPosition)
        this.scrollPercentage =  scrollAmount_Relative / (this.viewportHeight + this.showcaseInnerHeight)
    },
  },
  computed: {
    getTransformUppderRow(){
        let newHorizontalPositionUpperRow = this.startHorizontalPositionUpperRow + (this.scrollPercentage * this.maxScrollWidth)
        let targetHorizontalPosition_CSS = "translate3d(" + newHorizontalPositionUpperRow + "px, 0px, 0px)";
        return {
            'targetHorizontalPosition_CSS' : targetHorizontalPosition_CSS,
            'newHorizontalPositionUpperRow' : newHorizontalPositionUpperRow
        }
    },
    getTransformBottomRow(){
        let newHorizontalPositionBottomRow = this.startHorizontalPositionBottomRow - (this.scrollPercentage * this.maxScrollWidth)
        let targetHorizontalPosition_CSS = "translate3d(" + newHorizontalPositionBottomRow + "px, 0px, 0px)";
        return {
            'targetHorizontalPosition_CSS' : targetHorizontalPosition_CSS,
            'newHorizontalPositionUpperRow' : newHorizontalPositionBottomRow
        }
    }
  },
  mounted() {
      
  }
}
</script>

<style scoped>
#imageSchowCaseWrapper{
    padding-bottom: 160px;
}
@media screen and (max-width: 800px) {
  #imageSchowCaseWrapper{
    padding-bottom: 80px;
  }
}
.gallery.am2-section {
    padding: 0;
}
.gallery {
    overflow: hidden;
}
@media (min-width: 1024px){
}
.am2-section {
    position: relative;
}

.gallery__row:not(:last-child) {
    margin-bottom: 30px;
}
.gallery__row--top {

}
.gallery__row {
    display: flex;
    gap: 30px;
    border-radius: 20px;

}

@media (min-width: 1340px){
    .gallery__row-item {
        min-width: 300px;
    }
}

@media (min-width: 768px){
    .gallery__row-item {
        min-width: 270px;
        max-width: 270px;
    }
}

@media (max-width: 767px){
    .gallery__row {
        gap: 15px;
    }
}
.gallery__row-item {
    min-width: 240px;
    border-radius: 20px;
}
img {
    border: 0;
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
}
.gallery__row--bottom {
    flex-direction: row-reverse;

}
</style>
